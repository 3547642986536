import React from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import './AppTile.css';

function AppTile({ name, image, path }) {
  return (
    <Link to={path} className="app-tile">
      <div className="app-tile-content">
        <Box
          component="img"
          src={image}
          alt={`${name} logo`}
          style={{
            width: '160px',
            height: '160px',
            objectFit: 'contain',
            marginBottom: '8px',
          }}
        />
        <h2>{name}</h2>
      </div>
    </Link>
  );
}

export default AppTile;
