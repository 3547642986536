import React from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';

const DetailsTable = ({ income, card, cash, refundTarget, refundCardTarget, refundCashTarget, refund }) => {
  return (
    <TableContainer component={Paper} style={{ marginTop: '16px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>계산 방법</TableCell>
            <TableCell align="right">항목</TableCell>
            <TableCell align="right">금액 (원)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell rowSpan={8}>
              총 공제 대상 = 총 사용액 - 연 소득 × 25%<br />
              (비 공제 대상은 신용카드 사용액부터 차감)<br />
              <br />
              신용카드 공제액 = 신용카드 공제 대상 × 15%<br />
              현금/체크 공제액 = 현금/체크 공제 대상 × 30%<br />
              <br />
              소득공제액 = 신용카드 공제액 + 현금/체크 공제액<br />
              (단, 연 소득 7,000만원 초과 시 최대 250만원, 이하 시 최대 300만원)
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>연 소득</TableCell>
            <TableCell align="right">{(parseFloat(income) * 10000).toLocaleString()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>총 사용액</TableCell>
            <TableCell align="right">{((parseFloat(card) + parseFloat(cash)) * 10000).toLocaleString()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>총 공제 대상</TableCell>
            <TableCell align="right">{refundTarget.toLocaleString()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>신용카드 공제 대상</TableCell>
            <TableCell align="right">{refundCardTarget.toLocaleString()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>현금/체크 공제 대상</TableCell>
            <TableCell align="right">{refundCashTarget.toLocaleString()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>신용카드 공제액</TableCell>
            <TableCell align="right">{(refundCardTarget * 0.15).toLocaleString()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>현금/체크 공제액</TableCell>
            <TableCell align="right">{(refundCashTarget * 0.3).toLocaleString()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>소득공제액</TableCell>
            <TableCell align="right" colSpan={2}>
              {refund.toLocaleString()}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DetailsTable;
