import React from 'react';
import { Box, Container, IconButton, Link, Paper, Typography } from '@mui/material';
import AppTile from './AppTile';
import BrightnessMediumIcon from '@mui/icons-material/BrightnessMedium';

function HomePage({ toggleTheme }) {
  return (
    <Container maxWidth="md">
      <Paper elevation={3} style={{ padding: '16px', marginTop: '16px', marginBottom: '16px' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5"><b>Ace Apps</b></Typography>
          <IconButton onClick={toggleTheme}>
            <BrightnessMediumIcon />
          </IconButton>
        </Box>
        <Box display="flex" flexWrap="wrap" justifyContent="space-around" mt={2}>
          <AppTile
            name="계산기"
            image="/images/calculator.png"
            path="/calculator"
          />
          <AppTile
            name="신용카드 소득공제 계산기"
            image="/images/creditcard.png"
            path="/card-tax-refund"
          />
        </Box>
        <Box mt={4} textAlign="center">
          <Typography variant="body1">
            <Link href="https://cafe.daum.net/ace-apps" target="_blank" rel="noopener">
              개발자 페이지
            </Link>
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
}

export default HomePage;
