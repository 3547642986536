import React, { useState, useEffect } from 'react';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './home/HomePage';
import Calculator from './calculator/Calculator';
import CardTaxRefund from './card-tax-refund/CardTaxRefund';

function App() {
  const [darkMode, setDarkMode] = useState(() => localStorage.getItem('darkMode') === 'true');

  const toggleTheme = () => {
    setDarkMode(!darkMode);
  };

  useEffect(() => {
    localStorage.setItem('darkMode', darkMode);
  }, [darkMode]);

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
    },
  });

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<HomePage toggleTheme={toggleTheme} />} />
          <Route path="/calculator" element={<Calculator toggleTheme={toggleTheme} />} />
          <Route path="/card-tax-refund" element={<CardTaxRefund toggleTheme={toggleTheme} />} />
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
