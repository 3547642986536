import React, { useState } from "react";
import {
  Box,
  Container,
  IconButton,
  Link,
  Paper,
  TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
  Typography,
  TextField,
  Button,
  Grid,
  Tooltip,
  Radio,
  RadioGroup,
  FormControlLabel,
  Collapse
} from "@mui/material";
import BrightnessMediumIcon from '@mui/icons-material/BrightnessMedium';
import DetailsTable from "./DetailsTable";

const defaultValues = {
  income1: 1000,
  card1: 300,
  cash1: 0,
  income2: 0,
  card2: 0,
  cash2: 0,
};

function CardTaxRefund({ toggleTheme }) {
  const [familyType, setFamilyType] = useState('single');
  const [income1, setIncome1] = useState(defaultValues.income1);
  const [card1, setExpenses1] = useState(defaultValues.card1);
  const [cash1, setCash1] = useState(defaultValues.cash1);
  const [income2, setIncome2] = useState(defaultValues.income2);
  const [card2, setExpenses2] = useState(defaultValues.card2);
  const [cash2, setCash2] = useState(defaultValues.cash2);
  const [refundTarget1, setRefundTarget1] = useState(null);
  const [refundCardTarget1, setRefundCardTarget1] = useState(null);
  const [refundCashTarget1, setRefundCashTarget1] = useState(null);
  const [refund1, setRefund1] = useState(null);
  const [refundTarget2, setRefundTarget2] = useState(null);
  const [refundCardTarget2, setRefundCardTarget2] = useState(null);
  const [refundCashTarget2, setRefundCashTarget2] = useState(null);
  const [refund2, setRefund2] = useState(null);
  const [totalIncomeRefund, setTotalIncomeRefund] = useState(null);
  const [totalTaxRefund, setTotalTaxRefund] = useState(null);
  const [recommendation, setRecommendation] = useState({ type: '' });
  const [showDetails, setShowDetails] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleFamilyTypeChange = (event) => {
    setFamilyType(event.target.value);
    setRefundTarget1(null);
    setRefundCardTarget1(null);
    setRefundCashTarget1(null);
    setRefund1(null);
    setRefundTarget2(null);
    setRefundCardTarget2(null);
    setRefundCashTarget2(null);
    setRefund2(null);
    setTotalIncomeRefund(null);
    setTotalTaxRefund(null);
    setRecommendation({ type: '' });
    setShowDetails(false);
    setErrorMessage('');
  };

  const calculateTax = (income) => {
    let tax = 0;
    if (income <= 14000000) {
      tax = income * 0.06;
    } else if (income <= 50000000) {
      tax = income * 0.15 - 1260000;
    } else if (income <= 88000000) {
      tax = income * 0.24 - 5760000;
    } else if (income <= 150000000) {
      tax = income * 0.35 - 15440000;
    } else if (income <= 300000000) {
      tax = income * 0.38 - 19940000;
    } else if (income <= 500000000) {
      tax = income * 0.40 - 25940000;
    } else if (income <= 1000000000) {
      tax = income * 0.42 - 35940000;
    } else {
      tax = income * 0.45 - 65940000;
    }
    return Math.floor(tax);
  }

  const _calculateRefund = (income, card, cash) => {
    let refundExclude = income * 0.25;
    const refundTarget = card + cash - refundExclude;

    let refundCardTarget = card;
    if (refundCardTarget > refundExclude) {
      refundCardTarget -= refundExclude;
      refundExclude = 0;
    } else {
      refundExclude -= refundCardTarget;
      refundCardTarget = 0;
    }

    let refundCashTarget = cash;
    if (refundCashTarget > refundExclude) {
      refundCashTarget -= refundExclude;
      refundExclude = 0;
    } else {
      refundExclude -= refundCashTarget;
      refundCashTarget = 0;
    }

    if (refundExclude > 0) {
      // 사용액이 소득의 25% 미만인 경우
      return [0, 0, 0, 0, 0];
    }

    let incomeRefund = refundCardTarget * 0.15 + refundCashTarget * 0.3;
    if (income > 70000000) {
      // 연 소득이 7000만원 초과인 경우
      incomeRefund = Math.min(incomeRefund, 2500000);
    } else {
      // 연 소득이 7000만원 이하인 경우
      incomeRefund = Math.min(incomeRefund, 3000000);
    }
    incomeRefund = Math.min(income, incomeRefund);

    let taxRefund = calculateTax(income) - calculateTax(income - incomeRefund);

    return [refundTarget, refundCardTarget, refundCashTarget, incomeRefund, taxRefund];
  };

  const calculateMaxRefund = (income, use) => {
    let refundExclude = Math.floor(income * 0.25);
    const refundTarget = use - refundExclude;
    let incomeRefund = Math.floor(refundTarget * 0.3);
    if (income > 70000000) {
      incomeRefund = Math.min(incomeRefund, 2500000);
    } else {
      incomeRefund = Math.min(incomeRefund, 3000000);
    }
    incomeRefund = Math.min(income, incomeRefund);

    if (incomeRefund > 0) {
      let maxCardUse = refundExclude;
      let maxCashUse = Math.floor(incomeRefund / 0.3);
      let taxRefund = calculateTax(income) - calculateTax(income - incomeRefund);
      return [maxCardUse, maxCashUse, incomeRefund, taxRefund];
    }
    return [0, 0, 0, 0];
  };

  const generateRecommendationForSingle = (income, card, cash, taxRefund) => {
    const recommendation = {};
    const totalUse = card + cash;
    if (taxRefund === 0) {
      recommendation.type = 'NoRefund';
    } else {
      const [maxCardUse, maxCashUse, maxIncomeRefund, maxTaxRefund] = calculateMaxRefund(income, totalUse);
      if (taxRefund < maxTaxRefund) {
        recommendation.type = 'SingleRefund';
        recommendation.reduceCard = (card - maxCardUse);
        recommendation.increaseTaxRefund = (maxTaxRefund - taxRefund);
      } else {
        recommendation.type = 'MaxRefund';
        recommendation.totalUse = totalUse;
        recommendation.maxUse = maxCardUse + maxCashUse;
        recommendation.exceedUse = totalUse - recommendation.maxUse;
      }
    }
    return recommendation;
  };

  const calculateRecommendationForDual = (mainCard, mainCash, subCard, subCash, tgtMainCard, tgtMainCash, tgtSubCard, tgtSubCash) => {
    let needMainCard = tgtMainCard - mainCard;
    let needMainCash = tgtMainCash - mainCash;
    let needSubCard = tgtSubCard - subCard;
    let needSubCash = tgtSubCash - subCash;
    if (needMainCard > 0 && needSubCash < 0) {
      const sum = needSubCash + needMainCard;
      needSubCash = (sum > 0) ? 0 : sum;
      needMainCard = (sum > 0) ? sum : 0;
    }
    if (needMainCard > 0 && needSubCard < 0) {
      const sum = needSubCard + needMainCard;
      needSubCard = (sum > 0) ? 0 : sum;
      needMainCard = (sum > 0) ? sum : 0;
    }
    if (needMainCard > 0 && needMainCash < 0) {
      const sum = needMainCash + needMainCard;
      needMainCash = (sum > 0) ? 0 : sum;
      needMainCard = (sum > 0) ? sum : 0;
    }
    if (needMainCash > 0 && needMainCard < 0) {
      const sum = needMainCard + needMainCash;
      needMainCard = (sum > 0) ? 0 : sum;
      needMainCash = (sum > 0) ? sum : 0;
    }
    if (needMainCash > 0 && needSubCash < 0) {
      const sum = needSubCash + needMainCash;
      needSubCash = (sum > 0) ? 0 : sum;
      needMainCash = (sum > 0) ? sum : 0;
    }
    if (needMainCash > 0 && needSubCard < 0) {
      const sum = needSubCard + needMainCash;
      needSubCard = (sum > 0) ? 0 : sum;
      needMainCash = (sum > 0) ? sum : 0;
    }
    if (needSubCard > 0 && needSubCash < 0) {
      const sum = needSubCash + needSubCard;
      needSubCash = (sum > 0) ? 0 : sum;
      needSubCard = (sum > 0) ? sum : 0;
    }
    if (needSubCard > 0 && needMainCash < 0) {
      const sum = needMainCash + needSubCard;
      needMainCash = (sum > 0) ? 0 : sum;
      needSubCard = (sum > 0) ? sum : 0;
    }
    if (needSubCard > 0 && needMainCard < 0) {
      const sum = needMainCard + needSubCard;
      needMainCard = (sum > 0) ? 0 : sum;
      needSubCard = (sum > 0) ? sum : 0;
    }
    if (needSubCash > 0 && needSubCard < 0) {
      const sum = needSubCard + needSubCash;
      needSubCard = (sum > 0) ? 0 : sum;
      needSubCash = (sum > 0) ? sum : 0;
    }
    if (needSubCash > 0 && needMainCash < 0) {
      const sum = needMainCash + needSubCash;
      needMainCash = (sum > 0) ? 0 : sum;
      needSubCash = (sum > 0) ? sum : 0;
    }
    if (needSubCash > 0 && needMainCard < 0) {
      const sum = needMainCard + needSubCash;
      needMainCard = (sum > 0) ? 0 : sum;
      needSubCash = (sum > 0) ? sum : 0;
    }
    return [tgtMainCard - needMainCard, tgtMainCash - needMainCash, tgtSubCard - needSubCard, tgtSubCash - needSubCash];
  };

  const generateRecommendationForDual = (income1, card1, cash1, taxRefund1, income2, card2, cash2, taxRefund2) => {
    const recommendation = {};

    const totalUse = card1 + cash1 + card2 + cash2;
    const totalTaxRefund = taxRefund1 + taxRefund2;
    const [mainCard1, mainCash1, mainIncomeRefund1, mainTaxRefund1] = calculateMaxRefund(income1, totalUse);
    const [subCard2, subCash2, subIncomeRefund2, subTaxRefund2] = calculateMaxRefund(income2, totalUse - (mainCard1 + mainCash1));
    const [mainCard2, mainCash2, mainIncomeRefund2, mainTaxRefund2] = calculateMaxRefund(income2, totalUse);
    const [subCard1, subCash1, subIncomeRefund1, subTaxRefund1] = calculateMaxRefund(income1, totalUse - (mainCard2 + mainCash2));
    const maxTaxRefund1 = mainTaxRefund1 + subTaxRefund2;
    const maxTaxRefund2 = mainTaxRefund2 + subTaxRefund1;
    const maxTaxRefund = Math.max(maxTaxRefund1, maxTaxRefund2);
    const maxUse = (maxTaxRefund1 > maxTaxRefund2) ?
      mainCard1 + mainCash1 + subCard2 + subCash2 :
      mainCard2 + mainCash2 + subCard1 + subCash1;

    if (maxTaxRefund === 0) {
      recommendation.type = 'NoRefund';
    } else if (maxTaxRefund === totalTaxRefund) {
      recommendation.type = 'MaxRefund';
      recommendation.totalUse = totalUse;
      recommendation.maxUse = maxUse;
      recommendation.exceedUse = totalUse - maxUse;
    } else if (income1 === income2) {
      const [maxCard1, maxCash1, maxCard2, maxCash2] = (card1 + cash1 > card2 + cash2) ?
        calculateRecommendationForDual(
          card1, cash1, card2, cash2, mainCard1, mainCash1, subCard2, subCash2) :
        calculateRecommendationForDual(
          card2, cash2, card1, cash1, mainCard2, mainCash2, subCard1, subCash1);
      recommendation.type = 'DualEqualIncome';
      recommendation.increaseTaxRefund = maxTaxRefund - totalTaxRefund;
      recommendation.maxCard1 = maxCard1;
      recommendation.maxCash1 = maxCash1;
      recommendation.maxCard2 = maxCard2;
      recommendation.maxCash2 = maxCash2;
      recommendation.maxIncomeRefund = mainIncomeRefund1 + subIncomeRefund2;
      recommendation.maxTaxRefund = maxTaxRefund;
    } else if (maxTaxRefund1 > maxTaxRefund2 || (maxTaxRefund1 === maxTaxRefund2 && card1 + cash1 > card2 + cash2)) {
      const [maxCard1, maxCash1, maxCard2, maxCash2] =
        calculateRecommendationForDual(
          card1, cash1, card2, cash2, mainCard1, mainCash1, subCard2, subCash2);
      recommendation.type = 'DualRefund';
      recommendation.increaseTaxRefund = maxTaxRefund - totalTaxRefund;
      recommendation.maxCard1 = maxCard1;
      recommendation.maxCash1 = maxCash1;
      recommendation.maxCard2 = maxCard2;
      recommendation.maxCash2 = maxCash2;
      recommendation.maxIncomeRefund = mainIncomeRefund1 + subIncomeRefund2;
      recommendation.maxTaxRefund = maxTaxRefund;
    } else {  // maxTaxRefund1 < maxTaxRefund2
      const [maxCard2, maxCash2, maxCard1, maxCash1] =
        calculateRecommendationForDual(
          card2, cash2, card1, cash1, mainCard2, mainCash2, subCard1, subCash1);
      recommendation.type = 'DualRefund';
      recommendation.increaseTaxRefund = maxTaxRefund - totalTaxRefund;
      recommendation.maxCard1 = maxCard1;
      recommendation.maxCash1 = maxCash1;
      recommendation.maxCard2 = maxCard2;
      recommendation.maxCash2 = maxCash2;
      recommendation.maxIncomeRefund = mainIncomeRefund2 + subIncomeRefund1;
      recommendation.maxTaxRefund = maxTaxRefund;
    }

    return recommendation;
  };

  const calculateRefund = () => {
    const _income1 = parseFloat(income1) * 10000;
    const _card1 = parseFloat(card1) * 10000;
    const _cash1 = parseFloat(cash1) * 10000;
    const _income2 = parseFloat(income2) * 10000;
    const _card2 = parseFloat(card2) * 10000;
    const _cash2 = parseFloat(cash2) * 10000;

    setErrorMessage('');
    if (familyType === 'single') {
      if (_income1) {
        const [totalTarget, cardTarget, cashTarget, incomeRefund, taxRefund] = _calculateRefund(_income1, _card1, _cash1);
        setRefundTarget1(totalTarget);
        setRefundCardTarget1(cardTarget);
        setRefundCashTarget1(cashTarget);
        setRefund1(incomeRefund);
        setTotalIncomeRefund(incomeRefund);
        setTotalTaxRefund(taxRefund);
        setRecommendation(
          generateRecommendationForSingle(
            _income1, _card1, _cash1, taxRefund));
      } else {
        setErrorMessage('연 소득을 입력해주세요.');
      }
    } else {
      if (_income1 && _income2) {
        const [totalTarget1, cardTarget1, cashTarget1, incomeRefund1, taxRefund1] = _calculateRefund(_income1, _card1, _cash1);
        const [totalTarget2, cardTarget2, cashTarget2, incomeRefund2, taxRefund2] = _calculateRefund(_income2, _card2, _cash2);
        setRefundTarget1(totalTarget1);
        setRefundCardTarget1(cardTarget1);
        setRefundCashTarget1(cashTarget1);
        setRefund1(incomeRefund1);
        setRefundTarget2(totalTarget2);
        setRefundCardTarget2(cardTarget2);
        setRefundCashTarget2(cashTarget2);
        setRefund2(incomeRefund2);
        setTotalIncomeRefund(incomeRefund1 + incomeRefund2);
        setTotalTaxRefund(taxRefund1 + taxRefund2);
        setRecommendation(
          generateRecommendationForDual(
            _income1, _card1, _cash1, taxRefund1,
            _income2, _card2, _cash2, taxRefund2));
      } else if (!_income1) {
        setErrorMessage('남편 연 소득을 입력해주세요.');
      } else {
        setErrorMessage('부인 연 소득을 입력해주세요.');
      }
    }
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} style={{ padding: '16px', marginTop: '16px', marginBottom: '16px' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5"><b>2024 신용카드 소득공제 계산기</b></Typography>
          <IconButton onClick={toggleTheme}>
            <BrightnessMediumIcon />
          </IconButton>
        </Box>

        <Box display="flex" mt={2} mb={2} alignItems="center">
          <RadioGroup row value={familyType} onChange={handleFamilyTypeChange}>
            <FormControlLabel value="single" control={<Radio />} label="외벌이" />
            <FormControlLabel value="dual" control={<Radio />} label="맞벌이" />
          </RadioGroup>
        </Box>

        <Grid container spacing={2} justifyContent="center">
          {familyType === 'single' ? (
            <>
              <Grid item xs={12}>
                <Tooltip title="세전 연봉 + 인센티브">
                  <TextField
                    label="연 소득 (만원)"
                    fullWidth
                    value={income1}
                    onChange={(e) => setIncome1(e.target.value)}
                    variant="outlined"
                    type="number"
                    defaultValue={defaultValues.income1}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="연 신용카드 사용액 (만원)"
                  fullWidth
                  value={card1}
                  onChange={(e) => setExpenses1(e.target.value)}
                  variant="outlined"
                  type="number"
                  defaultValue={defaultValues.card1}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="연 현금영수증/체크카드 사용액 (만원)"
                  fullWidth
                  value={cash1}
                  onChange={(e) => setCash1(e.target.value)}
                  variant="outlined"
                  type="number"
                  defaultValue={defaultValues.cash1}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2} xs={12}>
                  <Grid item xs={12}>
                    <Tooltip title="세전 연봉 + 인센티브">
                      <TextField
                        label="남편 연 소득 (만원)"
                        fullWidth
                        value={income1}
                        onChange={(e) => setIncome1(e.target.value)}
                        variant="outlined"
                        type="number"
                        defaultValue={defaultValues.income1}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="남편 연 신용카드 사용액 (만원)"
                      fullWidth
                      value={card1}
                      onChange={(e) => setExpenses1(e.target.value)}
                      variant="outlined"
                      type="number"
                      defaultValue={defaultValues.card1}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="남편 연 현금영수증/체크카드 사용액 (만원)"
                      fullWidth
                      value={cash1}
                      onChange={(e) => setCash1(e.target.value)}
                      variant="outlined"
                      type="number"
                      defaultValue={defaultValues.cash1}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2} xs={12}>
                  <Grid item xs={12}>
                    <Tooltip title="세전 연봉 + 인센티브">
                      <TextField
                        label="부인 연 소득 (만원)"
                        fullWidth
                        value={income2}
                        onChange={(e) => setIncome2(e.target.value)}
                        variant="outlined"
                        type="number"
                        defaultValue={defaultValues.income2}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="부인 연 신용카드 사용액 (만원)"
                      fullWidth
                      value={card2}
                      onChange={(e) => setExpenses2(e.target.value)}
                      variant="outlined"
                      type="number"
                      defaultValue={defaultValues.card2}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="부인 연 현금영수증/체크카드 사용액 (만원)"
                      fullWidth
                      value={cash2}
                      onChange={(e) => setCash2(e.target.value)}
                      variant="outlined"
                      type="number"
                      defaultValue={defaultValues.cash2}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>

        <Box mt={4} textAlign="center">
          <Button variant="contained" color="primary" onClick={calculateRefund}>
            계산하기
          </Button>
        </Box>

        {errorMessage && (
          <Box mt={4}>
            <Typography variant="body1" color="error" align="center">
              {errorMessage}
            </Typography>
          </Box>
        )}

        {totalIncomeRefund !== null && (
          <Box mt={4}>
            <Typography variant="h5" align="center">
              예상 소득공제액: <b>{totalIncomeRefund.toLocaleString()} 원</b>
            </Typography>
            <Typography variant="h5" align="center">
              예상 절세액: <b>{totalTaxRefund.toLocaleString()} 원</b>
            </Typography>

            <Typography display="block" variant="caption" align="right" color="grey" style={{ marginTop: '8px' }}>
              실제 소득공제액은 기타 조건에 따라 달라 질 수 있습니다.<br />
              연말정산 계획을 위한 참고 자료로 활용해주세요.<br />
            </Typography>

            <Box textAlign="right">
              <Button onClick={() => setShowDetails(!showDetails)} variant="outlined">
                {showDetails ? '자세히 보기 닫기' : '자세히 보기'}
              </Button>
              <Collapse in={showDetails}>
                {familyType === 'single' && (
                  <DetailsTable income={income1} card={card1} cash={cash1} refundTarget={refundTarget1} refundCardTarget={refundCardTarget1} refundCashTarget={refundCashTarget1} refund={refund1} />
                )}
                {familyType === 'dual' && (
                  <Box>
                    <Typography variant="h6" align="center" style={{ marginTop: '16px' }}>
                      남편
                    </Typography>
                    <DetailsTable income={income1} card={card1} cash={cash1} refundTarget={refundTarget1} refundCardTarget={refundCardTarget1} refundCashTarget={refundCashTarget1} refund={refund1} />
                    <Typography variant="h6" align="center" style={{ marginTop: '16px' }}>
                      부인
                    </Typography>
                    <DetailsTable income={income2} card={card2} cash={cash2} refundTarget={refundTarget2} refundCardTarget={refundCardTarget2} refundCashTarget={refundCashTarget2} refund={refund2} />
                  </Box>
                )}
              </Collapse>
            </Box>

            <Box align="left" style={{ marginTop: '16px', marginLeft: '5%', marginRight: '5%' }}>
              {recommendation.type === 'NoRefund' && (
                <>
                  <Typography variant="body1">
                    👉 지출액이 연 소득의 <b>25% 미만</b>으로 소득공제 대상이 아닙니다.
                  </Typography>
                  <Typography variant="body2">
                    소득공제를 받기 위해 지출을 늘릴 필요는 없습니다.
                  </Typography>
                </>
              )}

              {recommendation.type === 'SingleRefund' && (
                <>
                  <Typography variant="body1">
                    👉 신용카드 사용액 <b>{recommendation.reduceCard.toLocaleString()}원</b>을 현금영수증/체크카드로 사용하면
                    절세액이 <b>{recommendation.increaseTaxRefund.toLocaleString()}원</b> 늘어납니다.
                  </Typography>
                </>
              )}

              {recommendation.type === 'MaxRefund' && (
                <>
                  <Typography variant="body1">
                    👏👏👏소득공제를 최대로 받습니다.👏👏👏
                  </Typography>
                  {recommendation.totalUse > recommendation.maxUse + 100000 && (
                    <>
                      <Typography variant="body2" style={{ marginTop: '8px' }}>
                        총 사용액은 {recommendation.totalUse.toLocaleString()}원 입니다. <br />
                        최대 소득공제를 위한 최소 사용액은 {recommendation.maxUse.toLocaleString()}원 입니다. <br />
                        <b>{recommendation.exceedUse.toLocaleString()}원</b>을 덜 사용해도 소득공제를 최대로 받을 수 있습니다.
                      </Typography>
                    </>
                  )}
                </>
              )}

              {recommendation.type === 'DualEqualIncome' && (
                <>
                  <Typography variant="body1">
                    👉 부부소득이 동일합니다. <br />
                    아래와 같이 사용액을 조정하면
                    절세액이 <b>{recommendation.increaseTaxRefund.toLocaleString()}원</b> 늘어납니다.
                  </Typography>

                  <TableContainer style={{ marginTop: '8px', marginLeft: '5%' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>사용 항목</TableCell>
                        <TableCell>금액(원)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>주 소비인 신용카드 사용액</TableCell>
                        <TableCell align="right">{recommendation.maxCard1.toLocaleString()}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>주 소비인 현금영수증/체크카드 사용액</TableCell>
                        <TableCell align="right">{recommendation.maxCash1.toLocaleString()}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>부 소비인 신용카드 사용액</TableCell>
                        <TableCell align="right">{recommendation.maxCard2.toLocaleString()}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>부 소비인 현금영수증/체크카드 사용액</TableCell>
                        <TableCell align="right">{recommendation.maxCash2.toLocaleString()}</TableCell>
                      </TableRow>
                    </TableBody>
                  </TableContainer>
                  <Typography variant="body1" style={{ marginTop: '8px', marginLeft: '5%' }}>
                    조정 후 예상 소득공제액: <b>{recommendation.maxIncomeRefund.toLocaleString()}원</b> <br />
                    조정 후 예상 절세액: <b>{recommendation.maxTaxRefund.toLocaleString()}원</b>
                  </Typography>
                </>
              )}

              {recommendation.type === 'DualRefund' && (
                <>
                  <Typography variant="body1">
                    👉 아래와 같이 사용액을 조정하면
                    절세액이 <b>{recommendation.increaseTaxRefund.toLocaleString()}원</b> 늘어납니다.
                  </Typography>

                  <TableContainer style={{ marginTop: '8px', marginLeft: '5%' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>사용 항목</TableCell>
                        <TableCell>금액(원)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>남편 신용카드 사용액</TableCell>
                        <TableCell align="right">{recommendation.maxCard1.toLocaleString()}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>남편 현금영수증/체크카드 사용액</TableCell>
                        <TableCell align="right">{recommendation.maxCash1.toLocaleString()}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>부인 신용카드 사용액</TableCell>
                        <TableCell align="right">{recommendation.maxCard2.toLocaleString()}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>부인 현금영수증/체크카드 사용액</TableCell>
                        <TableCell align="right">{recommendation.maxCash2.toLocaleString()}</TableCell>
                      </TableRow>
                    </TableBody>
                  </TableContainer>
                  <Typography variant="body1" style={{ marginTop: '8px', marginLeft: '5%' }}>
                    조정 후 예상 소득공제액: <b>{recommendation.maxIncomeRefund.toLocaleString()}원</b> <br />
                    조정 후 예상 절세액: <b>{recommendation.maxTaxRefund.toLocaleString()}원</b>
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        )}

        <Box mt={4} textAlign="center">
          <Typography variant="body1">
            <Link href="/">
              홈페이지
            </Link>
            {' | '}
            <Link href="https://cafe.daum.net/ace-apps" target="_blank" rel="noopener">
              개발자 페이지
            </Link>
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
}

export default CardTaxRefund;
