import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  IconButton,
  Box,
  Grid,
  TextField,
  Link,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button as MUIButton,
} from '@mui/material';
import { styled } from '@mui/system';
import BrightnessMediumIcon from '@mui/icons-material/BrightnessMedium';
import LabelIcon from '@mui/icons-material/Label';
import DeleteIcon from '@mui/icons-material/Delete';

const StyledButton = styled(MUIButton)(({ theme }) => ({
  height: '60px',
  fontSize: '30px',
  fontWeight: 'bold',
  borderRadius: '8px',
  '&.operator': {
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  '&.digit': {
    backgroundColor: theme.palette.info.main,
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    fontSize: '30px',
    fontWeight: 'bold',
    borderColor: theme.palette.primary.main,
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const TagTextField = styled(TextField)(() => ({
  marginTop: '10px',
  marginBottom: '10px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
}));

function Calculator({ toggleTheme }) {
  const [input, setInput] = useState('');
  const [currentTag, setCurrentTag] = useState('');
  const [history, setHistory] = useState(() => JSON.parse(localStorage.getItem('history')) || []);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedHistoryIndex, setSelectedHistoryIndex] = useState(null);

  const handleInputChange = () => {
    // 수동 입력 방지
  };

  const handleButtonClick = (value) => {
    // 중복 연산자 방지
    if (['+', '-', '*', '/'].includes(value) && ['+', '-', '*', '/'].includes(input.slice(-1))) {
      setInput(input.slice(0, -1) + value);
      return;
    }

    // 에러 메시지 초기화
    if (input === 'Error') {
      setInput(value);
      return;
    }

    setInput(input + value);
  };

  const handleClear = () => {
    setInput('');
  };

  const handBackspace = () => {
    setInput(input.slice(0, -1));
  };

  const handleCalculate = () => {
    try {
      const result = eval(input).toString();
      const newHistoryItem = { expression: input, result: result, tag: '' };
      const updatedHistory = [...history, newHistoryItem];
      setHistory(updatedHistory);
      localStorage.setItem('history', JSON.stringify(updatedHistory));
      setInput(result);
    } catch (error) {
      setInput('Error');
    }
  };

  const handleKeyPress = (event) => {
    if (openDialog) return;
    const validKeys = '0123456789/*-+.';
    if (validKeys.includes(event.key)) {
      setInput(input + event.key);
    } else if (event.key === 'Enter') {
      handleCalculate();
    } else if (event.key === 'Backspace') {
      setInput(input.slice(0, -1));
    }
  };

  const handleHistoryItemClick = (item) => {
    setInput(item.result);
  };

  const handleTagIconClick = (index) => {
    setSelectedHistoryIndex(index);
    setCurrentTag(history[index].tag || '');
    setOpenDialog(true);
  };

  const handleTagChange = (event) => {
    setCurrentTag(event.target.value);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDialogSave = () => {
    const updatedHistory = history.map((item, index) =>
      index === selectedHistoryIndex ? { ...item, tag: currentTag } : item
    );
    setHistory(updatedHistory);
    localStorage.setItem('history', JSON.stringify(updatedHistory));
    setOpenDialog(false);
  };

  const handleHistoryDelete = (index) => {
    const updatedHistory = history.filter((_, i) => i !== index);
    setHistory(updatedHistory);
    localStorage.setItem('history', JSON.stringify(updatedHistory));
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [input, openDialog]);

  return (
    <Container maxWidth="md">
      <Paper elevation={3} style={{ padding: '16px', marginTop: '16px', marginBottom: '16px' }}>
        <Box display="flex" justifyContent="flex-end">
          <IconButton onClick={toggleTheme}>
            <BrightnessMediumIcon />
          </IconButton>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <StyledTextField
              fullWidth
              margin="normal"
              variant="outlined"
              value={input}
              onChange={handleInputChange}
              inputProps={{ readOnly: true }}
            />
            <Grid container spacing={1}>
              {['7', '8', '9', '/'].map((value) => (
                <Grid item xs={3} key={value}>
                  <StyledButton
                    fullWidth
                    className={value === '/' ? 'operator' : 'digit'}
                    variant="contained"
                    onClick={() => handleButtonClick(value)}
                  >
                    {value}
                  </StyledButton>
                </Grid>
              ))}
              {['4', '5', '6', '*'].map((value) => (
                <Grid item xs={3} key={value}>
                  <StyledButton
                    fullWidth
                    className={value === '*' ? 'operator' : 'digit'}
                    variant="contained"
                    onClick={() => handleButtonClick(value)}
                  >
                    {value}
                  </StyledButton>
                </Grid>
              ))}
              {['1', '2', '3', '-'].map((value) => (
                <Grid item xs={3} key={value}>
                  <StyledButton
                    fullWidth
                    className={value === '-' ? 'operator' : 'digit'}
                    variant="contained"
                    onClick={() => handleButtonClick(value)}
                  >
                    {value}
                  </StyledButton>
                </Grid>
              ))}
              {['0', '.', '=', '+'].map((value) => (
                <Grid item xs={3} key={value}>
                  <StyledButton
                    fullWidth
                    className={['+', '='].includes(value) ? 'operator' : 'digit'}
                    variant="contained"
                    onClick={() => value === '=' ? handleCalculate() : handleButtonClick(value)}
                  >
                    {value}
                  </StyledButton>
                </Grid>
              ))}
              <Grid item xs={6}>
                <StyledButton
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={handleClear}
                >
                  CLEAR
                </StyledButton>
              </Grid>
              <Grid item xs={6}>
                <StyledButton
                  fullWidth
                  variant="contained"
                  color="error"
                  onClick={handBackspace}
                >
                  BACK
                </StyledButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6">히스토리</Typography>
            <List>
              {history.map((item, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton onClick={() => handleHistoryItemClick(item)}>
                    <ListItemText
                      primary={`${item.expression} = ${item.result}`}
                      secondary={item.tag || null}
                    />
                    <IconButton
                      edge="end"
                      aria-label="tag"
                      onClick={(e) => {
                        e.stopPropagation(); // 이벤트 전파 방지
                        handleTagIconClick(index);
                      }}
                    >
                      <LabelIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={(e) => {
                        e.stopPropagation(); // 이벤트 전파 방지
                        handleHistoryDelete(index);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
        <Box mt={4} textAlign="center">
          <Typography variant="body1">
            <Link href="/">
              홈페이지
            </Link>
            {' | '}
            <Link href="https://cafe.daum.net/ace-apps" target="_blank" rel="noopener">
              개발자 페이지
            </Link>
          </Typography>
        </Box>
      </Paper>
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>태그 추가</DialogTitle>
        <DialogContent>
          <DialogContentText>
            선택된 히스토리 항목에 대한 태그를 입력하세요.
          </DialogContentText>
          <TagTextField
            fullWidth
            margin="normal"
            variant="outlined"
            value={currentTag}
            onChange={handleTagChange}
          />
        </DialogContent>
        <DialogActions>
          <MUIButton onClick={handleDialogClose} color="primary">
            취소
          </MUIButton>
          <MUIButton onClick={handleDialogSave} color="primary">
            저장
          </MUIButton>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Calculator;
